.table--empty {
  min-height: 130px;
}
.table-assets {
  position: absolute;
  bottom: 35px;
  left: 50%;
  transform: translate(-50%, 0);
}

.table-assets--secondary {
  @extend .table-assets;
  bottom: 115px;
}

.table-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 1rem;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;

  .mat-cell,
  .mat-header-cell {
    padding: 0 1rem;
  }

  .no-data-message {
    display: block;
    box-sizing: border-box;
    padding: 1rem;
  }
}

.mat-table {
  width: 100%;

  .mat-cell,
  .mat-header-cell {
    &.clickable-value:hover {
      cursor: pointer;
      font-weight: bold;
      text-decoration: underline;
    }

    &.sorting-header:hover {
      cursor: pointer;
      color: black;
    }

    .mat-sort-header-button {
      text-transform: none;
      min-width: 0;
      white-space: normal;

      &:hover {
        color: var(--table-cell-color);
      }
    }

    .mat-sort-header-arrow {
      align-self: center;
    }
  }
}

.table-container--relative {
  position: relative;
}
