@use "@angular/material" as mat;
@import "~angular-material-css-vars/src/lib/main";

@import "./material-colors";
@import "./material-icons";
@import "./mixins";

@include init-material-css-vars();

$mat-cell-line-height: 15px;

.option-blue-hack .mat-pseudo-checkbox {
  background-color: transparent;
}

.option-blue-hack .mat-pseudo-checkbox-checked {
  background-color: rgba(var(--palette-primary-200));
}

div.mat-card-header-text {
  width: 100% !important;
}

.buttons {
  display: flex;
  justify-content: flex-end;

  .button,
  button {
    margin-left: 15px;
  }
}

::ng-deep .mat-checkbox {
  .mat-checkbox-layout {
    white-space: normal;
  }
}

div.download-ui {
  .mat-card {
    box-shadow: none;
    padding: 10px 24px;
  }
}

.cdk-global-scrollblock {
  overflow-y: hidden;
  top: 0 !important;
}

.mat-expansion-panel-header-title {
  font-size: 120%;
  font-weight: bold;
  color: var(--gray-2-color);
  line-height: 24px;

  span {
    color: black;
    display: inline-block;
    margin-left: 20px;
  }
}

.mat-menu-panel[role="menu"] {
  //14.5. Dropdowns
  border-radius: 0 0 5px 5px;
  min-height: 56px;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 10px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background: var(--gray-7-color);
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: var(--gray-3-color);
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: var(--gray-2-color);
  }
  .mat-menu-content {
    border-top: 2px solid var(--primary-color);
    border-radius: 0;
    overflow: hidden;
  }
  .mat-menu-item {
    height: 40px;
    line-height: 40px;
  }
}

.mat-expansion-panel-header {
  background-color: var(--gray-6-color);

  &.mat-expanded {
    &:hover,
    &:focus {
      background-color: var(--gray-6-color) !important;
    }
  }
}

.mat-tab-link {
  color: var(--gray-2-color);
  font-weight: 400;
  text-transform: uppercase;

  &.mat-tab-label-active {
    color: var(--gray-1-color);
    font-weight: bold;
  }
}
.mat-button-base {
  min-width: 144px;
  border-radius: 5px;
}
.no-hover-effect {
  &.mat-button {
    &.mat-primary,
    &.mat-accent,
    &.mat-warn {
      .mat-button-focus-overlay {
        background-color: transparent;
      }
    }
  }
}

.mat-table {
  border-radius: 10px 10px 0 0;
  overflow: hidden !important;
  border-bottom: 1px solid var(--table-border-color);

  .mat-row {
    border-bottom: 1px solid var(--table-row-border-color);

    &:nth-child(odd) {
      background-color: var(--table-row-odd-color);
    }

    &:nth-child(even) {
      background-color: var(--table-row-even-color);
    }

    &.active {
      background-color: var(--gray-3-color);
    }

    &:last-child {
      border-bottom: none;
    }
  }

  .mat-header-row {
    background-color: var(--gray-5-color);
    border-bottom: 1px solid var(--table-row-odd-color);
    min-height: 48px;
    line-height: 15px;

    .mat-header-cell {
      font-size: 14px;
      font-weight: bold;
      line-height: $mat-cell-line-height;
    }
  }

  .mat-cell {
    line-height: $mat-cell-line-height;

    i {
      padding: 5px;
    }

    &.ellipsis-cell {
      width: auto;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      display: block;
    }
  }

  .mat-cell,
  .mat-footer-cell,
  .mat-header-cell {
    min-height: auto;
  }

  &.table--empty,
  &.table--loading {
    min-height: 130px;
  }

  &.one-color--light {
    .mat-row {
      background-color: var(--table-row-even-color);
    }
  }

  &.one-color--dark {
    .mat-row {
      background-color: var(--table-row-odd-color);
    }
  }
}

.mat-tooltip {
  white-space: pre-line !important;
}

.mat-dialog-container {
  &#download-receipt {
    overflow-y: hidden !important;
  }
}

.mat-dialog-content {
  overflow: auto !important;
  overflow-x: hidden !important;
}

.mat-checkbox-inner-container,
.mat-pseudo-checkbox {
  height: 20px !important;
  width: 20px !important;
}

.mat-pseudo-checkbox-checked {
  &::after {
    width: 12px !important;
    height: 6px !important;
  }
}

// This is a 'temporary' fix which should be removed when tis-select is done correctly (see the new tis-input)
.mat-form-field-invalid {
  &.mat-form-field-appearance-legacy {
    label {
      color: var(--status-danger-color);
    }

    .mat-form-field-underline {
      background-color: var(--status-danger-color);
    }

    .mat-select-arrow {
      color: var(--status-danger-color);
    }
  }
}

.mat-paginator-page-size {
  .mat-paginator-page-size-select {
    width: 40px;
  }
}

mat-form-field {
  width: 100%;
}

.mat-calendar {
  .mat-calendar-body-label {
    visibility: hidden;
    padding-top: 0 !important;
  }
}

.mat-horizontal-stepper-content,
.mat-horizontal-content-container {
  overflow: visible !important;
}

.mat-header-row {
  background-color: var(--gray-6-color);
}

.cdk-overlay-container .mat-menu-panel .mat-menu-content {
  // fix to override bleeding css from TM
  width: unset;
}
