mat-chip {
  &.mat-chip {
    &:not(.mat-basic-chip) {
      background-color: transparent;
      border: 1px solid var(--primary-color);
      padding: 2px 8px;
      font-size: 13px;
      line-height: 18px;
      min-height: auto;
      height: auto;
      font-weight: 400;

      &:focus,
      &:hover {
        box-shadow: none;
      }

      &::after {
        background: transparent;
      }
    }
  }
}

.mat-chip-list-wrapper {
  padding-bottom: 5px;
}
