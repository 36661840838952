@import "~angular-material-css-vars/src/lib/public-util";

:root {
  --primary-color: rgba(var(--palette-primary-500));
  --secondary-color: rgb(24, 56, 94); //sidebar color
  --accent-color: rgba(var(--palette-accent-500));
  --warn-color: rgba(var(--palette-warn-500));
  --brand-color: rgba(var(--palette-primary-200));
  --brand-light-color: rgba(var(--palette-primary-100));

  /* colors */
  --gray-1: 51, 51, 51; //#333333;
  --gray-2: 127, 127, 127; //#7f7f7f;
  --gray-3: 178, 178, 178; //#b2b2b2;
  --gray-4: 214, 214, 214; //#d6d6d6;
  --gray-5: 226, 226, 226; //#e2e2e2;
  --gray-6: 236, 236, 236; //#ececec;
  --gray-7: 239, 239, 239; //#efefef;
  --gray-8: 245, 246, 246; //#f5f6f6;
  --gray-9: 245, 245, 245; //#f5f5f5;
  --gray-10: 255, 255, 255; //#fff;

  --status-driving: 219, 79, 96; //#db4f60
  --status-ok: 29, 136, 79; //#1d884f
  --status-warn: 243, 200, 53; //#f3c835
  --status-info: 122, 176, 219; //#7ab0db
  --status-alert: 168, 56, 1; //#a83801
  --status-danger: 219, 79, 96; //#db4f60
  --status-error: var(--status-danger); // alias for TM compat

  --gray-1-color: rgb(var(--gray-1));
  --gray-2-color: rgb(var(--gray-2));
  --gray-3-color: rgb(var(--gray-3));
  --gray-4-color: rgb(var(--gray-4));
  --gray-5-color: rgb(var(--gray-5));
  --gray-6-color: rgb(var(--gray-6));

  /* not part of the design colors, avoid using them */
  --gray-7-color: rgb(var(--gray-7));
  --gray-8-color: rgb(var(--gray-8));
  --gray-9-color: rgb(var(--gray-9));
  --gray-10-color: rgb(var(--gray-10));

  --sidebar-font-color: rgba(var(--palette-primary-100));
  --sidebar-font-color-active: var(--gray-10-color);

  --background: var(--gray-10-color);
  --error-background: rgba(var(--status-danger), 0.2);
  --warn-background: rgba(var(--status-warn), 0.2);
  --info-background: rgba(var(--status-info), 0.2);
  --ok-background: rgba(var(--status-ok), 0.2);

  --status-driving-color: rgb(var(--status-driving));
  --status-ok-color: rgb(var(--status-ok));
  --status-warn-color: rgb(var(--status-warn));
  --status-info-color: rgb(var(--status-info));
  --status-alert-color: rgb(var(--status-alert));
  --status-danger-color: rgb(var(--status-danger));
  --status-error-color: rgb(var(--status-error));

  --driver-status-driving: var(--status-driving-color);
  --driver-status-working: var(--status-info-color);
  --driver-status-available: var(--status-warn-color);
  --driver-status-resting: var(--status-ok-color);

  /* layout */
  --top-left-logo-width-wide: 180px;
  --menu-width-wide: 240px;
  --menu-width-narrow: 80px;
  --menu-item-height: 40px;
  --header-height: 65px;
  --toast-margin: 40px;
  --toast-height: 64px;
  --thin-gray-border: 1px solid var(--gray-4-color);
  --thick-gray-border: 2px solid var(--gray-4-color);
  --sidelist-width: 300px;
  --nav-height: 40px;
  --element-margin: 50px;
  --container-padding: 30px 40px;
  --container-padding-low: 12px 40px 30px;
  --circle-charts-minimal-dimensions: 175px;
  --navigation-bar-height: 60px;

  --table-row-color: #e4e5e5;
  --table-row-even-color: #fafbfb;
  --table-row-odd-color: var(--gray-8-color);
  --table-row-border-color: var(--gray-4-color);
  --table-border-color: #d4d4d4;
  --table-cell-color: #55595c;
  --table-header-text-color: rgba(0, 0, 0, 0.54);

  --reminder-spinner-background: rgba(var(--palette-background-background));
  --reminder-spinner-border-color: var(--gray-6-color);
  --overdue-color: var(--status-alert-color);
  --conflicted-row-color: rgba(var(--status-danger), 0.39);
}
