mat-expansion-panel {
  &.background-panel {
    .mat-expansion-panel-header,
    .mat-expanded {
      background-color: var(--background);

      &:hover,
      &:active,
      &:focus {
        background-color: var(--background) !important;
      }
    }

    .mat-expansion-panel-body {
      .mat-table {
        .mat-row {
          background-color: var(--background);
        }
      }
    }
  }
}

mat-accordion {
  &:not([displaymode="flat"]) {
    lib-tis-collapsible-panel {
      margin-bottom: 10px;
    }
  }
}
