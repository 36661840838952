$Cglyphs-woff-font-path: "./fonts/Cglyphs.woff";
$Cglyphs2-woff-font-path: "./fonts/Cglyphs2.woff";
$MaterialIcons-path: "./fonts/MaterialIcons-Regular.woff";
$roboto-font-path: "./fonts/roboto";
$roboto-condensed-font-path: "./fonts/roboto-condensed";

@font-face {
	font-family: "Cglyphs";
  font-display: block;
	src: url($Cglyphs-woff-font-path) format("woff"), url($Cglyphs2-woff-font-path) format("woff");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Cglyphs2";
  font-display: block;
	src: url($Cglyphs2-woff-font-path) format("woff");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Material Icons";
	font-style: normal;
  font-display: block;
	font-weight: 400;
	src: url($MaterialIcons-path) format("woff");
}

@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 100;
	src:
 local("Roboto Thin"),
 local("Roboto-Thin"),
		url($roboto-font-path+"/roboto-v18-latin_greek-ext_latin-ext_cyrillic-ext_greek_cyrillic_vietnamese-100.woff")
			format("woff");
}

@font-face {
	font-family: "Roboto";
	font-style: italic;
	font-weight: 100;
	src:
 local("Roboto Thin Italic"),
 local("Roboto-ThinItalic"),
		url($roboto-font-path+"/roboto-v18-latin_greek-ext_latin-ext_cyrillic-ext_greek_cyrillic_vietnamese-100italic.woff")
			format("woff");
}

@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 300;
	src:
 local("Roboto Light"),
 local("Roboto-Light"),
		url($roboto-font-path+"/roboto-v18-latin_greek-ext_latin-ext_cyrillic-ext_greek_cyrillic_vietnamese-300.woff")
			format("woff");
}

@font-face {
	font-family: "Roboto";
	font-style: italic;
	font-weight: 300;
	src:
 local("Roboto Light Italic"),
 local("Roboto-LightItalic"),
		url($roboto-font-path+"/roboto-v18-latin_greek-ext_latin-ext_cyrillic-ext_greek_cyrillic_vietnamese-300italic.woff")
			format("woff");
}

@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	src:
 local("Roboto"),
 local("Roboto-Regular"),
		url($roboto-font-path+"/roboto-v18-latin_greek-ext_latin-ext_cyrillic-ext_greek_cyrillic_vietnamese-regular.woff")
			format("woff");
}

@font-face {
	font-family: "Roboto";
	font-style: italic;
	font-weight: 400;
	src:
 local("Roboto Italic"),
 local("Roboto-Italic"),
		url($roboto-font-path+"/roboto-v18-latin_greek-ext_latin-ext_cyrillic-ext_greek_cyrillic_vietnamese-italic.woff")
			format("woff");
}

@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 500;
	src:
 local("Roboto Medium"),
 local("Roboto-Medium"),
		url($roboto-font-path+"/roboto-v18-latin_greek-ext_latin-ext_cyrillic-ext_greek_cyrillic_vietnamese-500.woff")
			format("woff");
}

@font-face {
	font-family: "Roboto";
	font-style: italic;
	font-weight: 500;
	src:
 local("Roboto Medium Italic"),
 local("Roboto-MediumItalic"),
		url($roboto-font-path+"/roboto-v18-latin_greek-ext_latin-ext_cyrillic-ext_greek_cyrillic_vietnamese-500italic.woff")
			format("woff");
}

@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 700;
	src:
	local("Roboto Bold"),
	local("Roboto-Bold"),
    url($roboto-font-path+"/roboto-v18-latin_greek-ext_latin-ext_cyrillic-ext_greek_cyrillic_vietnamese-700.woff")
			 format("woff");
}

@font-face {
	font-family: "Roboto";
	font-style: italic;
	font-weight: 700;
	src:
 local("Roboto Bold Italic"),
 local("Roboto-BoldItalic"),
		url($roboto-font-path+"/roboto-v18-latin_greek-ext_latin-ext_cyrillic-ext_greek_cyrillic_vietnamese-700italic.woff")
			format("woff");
}

@font-face {
	font-family: "Roboto";
	font-style: italic;
	font-weight: 900;
	src:
 local("Roboto Black Italic"),
 local("Roboto-BlackItalic"),
		url($roboto-font-path+"/roboto-v18-latin_greek-ext_latin-ext_cyrillic-ext_greek_cyrillic_vietnamese-900italic.woff")
			format("woff");
}

@font-face {
	font-family: "Roboto Condensed";
	font-style: normal;
	font-weight: 300;
	src:
 local("Roboto Condensed Light"),
 local("RobotoCondensed-Light"),
		url($roboto-condensed-font-path+"/roboto-condensed-v16-latin_greek-ext_latin-ext_cyrillic-ext_greek_cyrillic_vietnamese-300.woff")
			format("woff");
}

@font-face {
	font-family: "Roboto Condensed";
	font-style: italic;
	font-weight: 300;
	src:
 local("Roboto Condensed Light Italic"),
 local("RobotoCondensed-LightItalic"),
		url($roboto-condensed-font-path+"/roboto-condensed-v16-latin_greek-ext_latin-ext_cyrillic-ext_greek_cyrillic_vietnamese-300italic.woff")
			format("woff");
}

@font-face {
	font-family: "Roboto Condensed";
	font-style: normal;
	font-weight: 400;
	src:
 local("Roboto Condensed"),
 local("RobotoCondensed-Regular"),
		url($roboto-condensed-font-path+"/roboto-condensed-v16-latin_greek-ext_latin-ext_cyrillic-ext_greek_cyrillic_vietnamese-regular.woff")
			format("woff");
}

@font-face {
	font-family: "Roboto Condensed";
	font-style: italic;
	font-weight: 400;
	src:
 local("Roboto Condensed Italic"),
 local("RobotoCondensed-Italic"),
		url($roboto-condensed-font-path+"/roboto-condensed-v16-latin_greek-ext_latin-ext_cyrillic-ext_greek_cyrillic_vietnamese-italic.woff")
			format("woff");
}

@font-face {
	font-family: "Roboto Condensed";
	font-style: normal;
	font-weight: 700;
	src:
 local("Roboto Condensed Bold"),
 local("RobotoCondensed-Bold"),
		url($roboto-condensed-font-path+"/roboto-condensed-v16-latin_greek-ext_latin-ext_cyrillic-ext_greek_cyrillic_vietnamese-700.woff")
			format("woff");
}

@font-face {
	font-family: "Roboto Condensed";
	font-style: italic;
	font-weight: 700;
	src:
 local("Roboto Condensed Bold Italic"),
 local("RobotoCondensed-BoldItalic"),
		url($roboto-condensed-font-path+"/roboto-condensed-v16-latin_greek-ext_latin-ext_cyrillic-ext_greek_cyrillic_vietnamese-700italic.woff")
			format("woff");
}
