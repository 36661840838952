nav {
  &.sidelist,
  &.tabs {
    position: fixed;
    top: var(--header-height);
    left: var(--menu-width-narrow);
    animation: appear-from-left forwards 0.5s;
    transition: width 0.25s, left 0.25s, padding 0.25s;
    background: var(--background);
  }

  &.sidelist {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    bottom: 0;
    background: var(--background);
    z-index: 20;
    flex: 0 0 auto;
    border-right: var(--thin-gray-border);
    padding-top: 12px;
    width: 300px;
    overflow: hidden;
    opacity: 0;
  }

  &.tabs {
    right: 0;
    height: var(--nav-height);
    padding: 20px 20px 0;
    background: var(--background);
    z-index: 50;
    animation: appear-from-top forwards 0.5s;
    left: var(--menu-width-narrow);
    border-bottom: var(--thin-gray-border);
  }
  &.subtabs {
    position: fixed;
    top: var(--header-height) + 60px;
    height: 50px;
    transition: left 0.25s;
    padding: 0 30px;
    z-index: 40;
    opacity: 0;
    animation: appear-from-top forwards 0.5s 0.1s;
  }
}

.custom-list-item {
  width: 100%;
  min-width: 100%;
  cursor: pointer;
  box-sizing: border-box;
  height: 70px;
  overflow: hidden;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-right: 3px solid transparent;
  padding: 4px;
  transition: background-color 0.5s, border-color 0.5s;

  > * {
    margin: 0 6px;
  }
}

.blur {
  nav {
    &.sidelist,
    &.tabs {
      left: var(--menu-width-wide);
    }
  }
}

@media (max-width: 1200px) {
  nav {
    &.tabs,
    &.subtabs {
      left: var(--menu-width-narrow);
    }
  }
}

@media (max-width: 700px) {
  nav {
    &.tabs,
    &.subtabs {
      left: 0;
    }
  }

  .blur {
    nav {
      &.sidelist,
      &.tabs {
        left: 0;
      }
    }
  }
}

.sidelist-sibling {
  margin-left: 300px;
  transition: margin 0.25s;
}

@media (max-width: 1200px) {
  nav {
    &.sidelist {
      left: var(--menu-width-narrow);
    }
  }
}

@media (max-width: 700px) {
  nav.sidelist {
    left: 0;
  }
}

@media (max-width: 1400px) {
  nav.sidelist:not(.wide) {
    width: 70px;
    padding-top: 0;
    --dropdown-display: none;
  }

  nav.sidelist:not(.wide) > ul li {
    width: 70px;
  }

  nav.sidelist:not(.wide) header {
    height: 0;
    overflow: hidden;
  }

  nav.sidelist:not(.wide) header h2 {
    letter-spacing: -0.6em;
  }

  nav.sidelist:not(.wide) .filters {
    height: 0;
  }

  .sidelist-sibling:not(.wide) {
    margin-left: 70px;
  }
}

@media (max-width: 600px) {
  nav.sidelist:not(.wide) {
    width: 0;
  }

  nav.sidelist:not(.wide) > ul li {
    width: 0;
  }

  .sidelist-sibling:not(.wide) {
    margin-left: 0;
  }
}

nav.sidelist header {
  position: relative;
  padding: 0 12px;
  display: flex;
  align-items: center;
  height: 60px;
  min-width: 250px;
  transition: height 0.25s;
}

nav.sidelist header h2 {
  flex: 1;
  text-transform: uppercase;
  white-space: nowrap;
  font-weight: 400;
  max-width: 300px;
  transition: max-width 0.25s, letter-spacing 0.3s, opacity 0.3s;
  overflow: hidden;
}

nav.sidelist header.search h2 {
  max-width: 0;
  letter-spacing: -0.6em;
  opacity: 0;
}

nav.sidelist > header input[type="search"] {
  flex: 1;
  min-width: 0;
  max-width: 0;
  transition: max-width 0.25s, padding 0.25s, margin 0.25s, opacity 0.3s;
}

nav.sidelist > header:not(.search) input[type="search"] {
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
  opacity: 0;
}

nav.sidelist > header.search input[type="search"] {
  max-width: 300px;
}

nav.sidelist > header button.search {
  overflow: hidden;
  transition: max-width 0.25s, min-width 0.25s, padding 0.25s, margin 0.25s,
    opacity 0.3s;
}

nav.sidelist > header.search button.search {
  max-width: 0;
  min-width: 0;
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
  opacity: 0;
}

nav.sidelist > header button,
nav.sidelist > .filters button {
  margin-left: 10px;
  color: var(--gray-2-color);
}

nav.sidelist .filters {
  display: flex;
  min-width: 250px;
  align-items: center;
  height: 60px;
  padding: 0 12px;
  background-color: var(--gray-4-color);
  transition: height 0.25s;
  overflow: hidden;
}

nav.sidelist .filters select {
  flex: 1;
  background-color: transparent;
  color: var(--gray-2-color);
  padding-left: 0;
  padding-right: 0;
}

nav.sidelist > ul {
  position: relative;
  flex: 1 1 0;
  margin: 0;
  width: 100%;
  padding-right: 20px;
  overflow-x: hidden;
  overflow-y: auto;

  &.no-items {
    flex: 0;
  }
}

nav.sidelist .no-items-info {
  flex: 1 1 0;
}

nav.sidelist > ul button {
  color: var(--gray-2-color);
}

nav.sidelist > ul li:not(.spinner-item) {
  overflow: hidden;
  display: flex;
  width: 300px;
  align-items: stretch;
  height: 0;
  border-bottom-color: var(--gray-5-color);
  border-bottom-style: solid;
  animation: appear-list-item forwards 0.25s;
  -ms-animation: appear-list-item forwards 0.25s;
  -moz-animation: appear-list-item forwards 0.25s;
  -o-animation: appear-list-item forwards 0.25s;
  -webkit-animation: appear-list-item forwards 0.25s;
  transition: width 0.25s;
}

nav.sidelist > ul li > a,
nav.sidelist > ul li > div {
  cursor: pointer;
  box-sizing: border-box;
  max-width: 100%;
  height: 70px;
  overflow: hidden;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-right: 3px solid transparent;
  padding: 4px;
  transition: background-color 0.5s, border-color 0.5s;
}

nav.sidelist > ul li {
  div.active,
  a.active {
    background-color: var(--gray-8-color);
    transition: background-color 0.1s, border-color 0.5s;
    pointer-events: none;
    cursor: default;
  }
}

nav.sidelist > ul li > a:hover,
nav.sidelist > ul li > div:hover {
  color: var(--primary-color);
}

nav.sidelist > ul li > a > *,
nav.sidelist > ul li > div > * {
  margin: 0 6px;
  overflow: hidden;
  max-width: 250px;
  /* too much animation */
  /* transition: opacity 0.25s, max-width 0.25s, margin 0.25s, letter-spacing 0.25s; */
}

@media (max-width: 1400px) {
  nav.sidelist:not(.wide) > ul {
    min-width: unset;
  }

  nav.sidelist:not(.wide) > ul li > a > :not(.avatar),
  nav.sidelist:not(.wide) > ul li > div > :not(.avatar) {
    display: none;
  }
}
