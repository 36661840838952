$ng-select-highlight: var(--primary-color) !default;

.ng-select {
  display: block;
  padding-bottom: 0;
  margin-bottom: 1.25em;
  position: relative;
  flex: auto;
  width: 180px;

  &:not(.ng-select-disabled) {
    .ng-select-container {
      cursor: pointer !important;
    }

    &.required:not(.ng-select-focused) {
      .ng-placeholder {
        &:after {
          content: " *";
          color: var(--gray-2-color);
        }
      }
    }

    &.required.ng-select-focused {
      .ng-placeholder {
        &:after {
          content: " *";
          color: var(--status-warn);
        }
      }
    }
  }

  .ng-select-container {
    min-height: 40px;
  }
}

.ng-dropdown-panel {
  &.ng-select-bottom {
    width: auto !important;
  }
}
