form {
	.form-group.horizontal {
		display: flex;

		.form-group {
			width: 100%;

			&.left,
			&.right {
				margin-right: 50px;
				flex-direction: column;
				display: flex;
				justify-content: flex-start;
				flex-grow: 1;
			}
		}
	}

	.form-section:not(:first-of-type),
	.mat-expansion-panel .mat-expansion-panel-body .form-section {
		margin-top: 35px;
	}
}

h2.form-group-title {
	margin-top: 40px;
}

.select--short {
	width: 25%;
}
.select--medium {
	width: 40%;
}


div.stepper-wrapper {
	margin: 0;
}


.add-item {
	position: relative;
}

div.add-item-wrapper {
	margin: 0;

	.form-group.horizontal {
		padding-top: 15px;
		display: flex;
	}

	form.add-item-form {
		max-width: 900px;
		display: flex;
		flex-grow: 1;
		flex-direction: column;
	}

	div.form-group.right,
	div.form-group.left {
		flex-direction: column;
		display: flex;
		justify-content: flex-start;
		flex-grow: 1;
	}

	div.form-group.right {
		margin-left: 50px;
	}

	div.form-group.left {
		margin-right: 50px;
	}

	tis-field-wrapper {
		max-width: 400px;
	}
}

@media (min-width: 900px) {
	div.add-item-wrapper {
		margin: 0 calc((100% - 900px) / 2);
	}
}

@media (min-width: 1200px) {
	div.stepper-wrapper {
		margin: 0 calc((100% - 1200px) / 2);
	}
}
