nav.tabs {
  font-size: 14px;
  color: var(--gray-2-color);
  background: var(--gray-7-color);
  box-shadow: 0 2px 4px -2px var(--gray-3-color) inset;
  text-transform: uppercase;
  display: flex;
  flex-flow: row wrap-reverse;
  max-width: 100%;
}

nav.tabs > a {
  box-sizing: border-box;
  height: 50px;
  line-height: 50px;
  display: inline-block;
  padding: 0 16px;
  white-space: nowrap;
  border-bottom: 3px solid transparent;
  transition: border-color 0.5s, color 0.5s;
}

nav.tabs > a.active {
  color: var(--gray-1-color);
  font-weight: 600;
}

nav.tabs > a.disabled {
  pointer-events: none;
  color: var(--gray-4-color);
}

nav.tabs > a:hover {
  color: var(--gray-1-color);
  transition: border-color 0.5s, color 0.1s;
}

nav.tabs.primary {
  font-size: 16px;
  background-color: transparent;
  box-shadow: none;
}

nav.tabs.primary > a {
  padding: 0 24px;
  height: 40px;
  line-height: 40px;
}

nav.tabs.primary > a.active {
  border-bottom-color: var(--primary-color);
}
