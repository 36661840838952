:root {
  font-family: "Roboto", "Roboto Condensed", sans-serif;
  font-size: 14px;
  color: var(--gray-1-color);
  word-wrap: break-word; // IE 11 fallback
  overflow-wrap: break-word;
}

h1 {
  font-weight: 700;
  font-size: 20px;
  color: var(--gray-1-color);
  text-transform: uppercase;
}

h2 {
  font-weight: 700;
  font-size: 20px;
  color: var(--gray-2-color);
  text-transform: uppercase;
}

h3 {
  font-weight: 700;
  font-size: 18px;
  color: var(--gray-3-color);
}

h2.dark {
  color: #000;
  font-weight: normal;
}

h3.dark {
  color: #000;
}

h4 {
  font-weight: 700;
  font-size: 18px;
  color: var(--gray-1-color);
}

h5 {
  font-weight: 400;
  font-size: 16px;
  color: var(--gray-3-color);
}

.info__full-page {
  text-align: center;
  font-weight: 100;
  font-size: 60px;
  color: var(--gray-1-color);
}

.info__heading {
  font-weight: 700;
  font-size: 18px;
  color: var(--gray-3-color);
  margin: 16px 0;
}

.info__heading--small {
  font-weight: 500;
  font-size: 14px;
  color: var(--gray-2-color);
  margin: 8px 0;
}

.no-data-message {
  color: var(--gray-2-color);
  font-size: 19px;
  text-align: center;
}
