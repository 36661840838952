.modal-box-background {
  position: absolute;
  z-index: 100;
  padding-top: 30px;
  left: 0;
  width: 80%;
  height: 88%;
  padding-left: var(--menu-width-wide);
}

.modal-box-content {
  background-color: var(--background);
  margin: auto;
  border: 1px solid var(--gray-3-color);
  border-radius: 5px;
  box-shadow: 0 1px 3px var(--gray-2-color);
  width: 70%;
  max-height: 100%;
  overflow-y: auto;
}

.modal-box-content h3 {
  color: var(--gray-1-color);
  text-transform: uppercase;
}

.modal-box-content p {
  color: var(--gray-2-color);
}

.modal-box-progress-bar,
.modal-box-progress-bar::before {
  height: 20px;
  width: 100%;
  margin: 0;
}

.modal-box-progress-bar {
  background-color: var(--gray-5-color);
  display: flex;
  padding: 2px;
}

.modal-box-progress-bar::before {
  content: "";
  background-color: var(--primary-color);
  animation: progress-bar-progress 3s cubic-bezier(0.1, 0, 0.3, 1) infinite;
}

@keyframes progress-bar-progress {
  0% {
    margin-left: 0;
    margin-right: 100%;
  }

  50% {
    margin-left: 30%;
    margin-right: 10%;
  }

  100% {
    margin-left: 100%;
    margin-right: 0;
  }
}

.modal-box-progress-bar-complete,
.modal-box-progress-bar-complete::before {
  height: 20px;
  width: 100%;
  margin: 0;
}

.modal-box-progress-bar-complete {
  background-color: var(--gray-5-color);
  display: flex;
  padding: 2px;
}

.modal-box-progress-bar-complete::before {
  content: "";
  background-color: var(--primary-color);
}

.modal-box-progress-bar-empty {
  height: 20px;
  width: 100%;
  margin: 0;
  background-color: var(--gray-5-color);
  display: flex;
  padding: 2px;
}

@media (max-width: 550px) {
  .modal-box-content {
    min-width: 85%;
  }
}
