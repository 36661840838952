.info {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}

.info > * {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: 24px;
  margin: 0;
  padding: 0;
}

.info > * > * {
  margin: 0;
  padding: 0;
}

.info > :nth-child(n + 2) {
  color: var(--gray-2-color);
  text-transform: uppercase;
  font-size: 88%;
}
