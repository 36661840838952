input.material-like,
select.material-like {
  font-size: 14px;
  display: block;
  border: none;
  border-bottom: 1px solid var(--gray-1-color);
  background-color: transparent;

  &:focus {
    outline: none;
    color: var(--gray-1-color);
  }
}

input.material-like {
  padding: 2px 0 5px;
  margin: 0 10px 0 5px;
  max-width: calc(100% - 15px);

  &.ng-invalid {
    color: var(--status-danger-color);
  }

  &:focus ~ .bar-material-like::before,
  &:focus ~ .bar-material-like::after,
  &.ng-invalid ~ .bar-material-like::before,
  &.ng-invalid ~ .bar-material-like::after {
    width: 50%;
  }
}

.bar-material-like {
  position: relative;
  display: block;
  width: 100%;

  &::before,
  &::after {
    content: "";
    height: 2px;
    width: 0;
    bottom: 0;
    position: absolute;
    background: var(--primary-color);
    transition: 0.2s ease all;
    z-index: 10;
  }

  &.invalid::before,
  &.invalid::after {
    background: var(--status-danger-color);
  }

  &::before {
    left: 50%;
  }

  &::after {
    right: 50%;
  }
}

select.material-like {
  padding-bottom: 5px;
  width: 100%;
}

.label__material-like {
  font-size: 11px;
  color: rgba(0, 0, 0, 0.54);
}

table.material-like {
  border-top-left-radius: 10px;
  border: 0;
  width: 100%;
  border-collapse: collapse;
  border-bottom: 1px solid var(--table-border-color);

  tr {
    &:first-child th {
      &:first-child {
        border-top-left-radius: 10px;
      }

      &:last-child {
        border-top-right-radius: 10px;
      }
    }

    &:last-child td {
      &:first-child {
        border-bottom-left-radius: 10px;
      }

      &:last-child {
        border-bottom-right-radius: 10px;
      }
    }
  }

  th,
  td {
    border: 0;
    line-height: 15px;
    height: 45px;

    &:first-child {
      padding-left: 24px;
    }

    &:last-child {
      padding-right: 24px;
    }
  }

  thead {
    border-bottom: 1px solid var(--table-row-border-color);

    th {
      color: var(--table-header-text-color);
      background-color: var(--gray-5-color);
      text-align: left;
    }

    tr:not(:first-of-type) {
      border-top: 1px solid var(--table-row-border-color);

      th {
        padding-left: 0;
      }
    }
  }

  tbody {
    tr {
      &:not(:last-child) {
        border-bottom: 1px solid var(--table-row-border-color);
      }

      &:nth-child(odd) {
        background-color: var(--table-row-even-color);
      }

      &:nth-child(even) {
        background-color: var(--table-row-odd-color);
      }
    }
  }
}

@keyframes inputHighlighterMaterialLike {
  from {
    background: var(--primary-color);
  }
  to {
    width: 0;
    background: transparent;
  }
}
