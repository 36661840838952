@mixin clearfix {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin center-absolute-v {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin error-text($margin: 0 0 0 0, $font-size: 13) {
  font-size: $font-size + px;
  color: var(--status-alert) !important;
  margin: $margin;
}

@mixin multiline-truncate-ellipsis(
  $max-lines-number: 2,
  $line-height: 1.2em,
  $background: white
) {
  overflow: hidden;
  position: relative;
  line-height: $line-height;
  max-height: $max-lines-number * $line-height;
  text-align: justify;
  margin-top: 0.75em;
  margin-right: -1em;
  padding-right: 1em;

  &::before {
    content: "...";
    position: absolute;
    right: 1px;
    bottom: 0;
  }

  &::after {
    content: "";
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: $background;
  }
}

@mixin increase-height($item-height) {
  from {
    height: 0;
    border-bottom-width: 0;
  }
  to {
    height: $item-height;
    border-bottom-width: 1px;
  }
}
