.block {
  clear: both;
}

.glyphs,
.glyphs2 {
  font-style: normal;
  text-transform: none;
}

.glyphs {
  font-family: "Cglyphs", monospace;
}

.glyphs2 {
  font-family: "Cglyphs2", monospace;
}

body {
  margin: 0;
  padding: 0;
  line-height: 1;
  box-sizing: border-box;
}

* {
  box-sizing: inherit;
}

ul,
ol {
  padding-left: 0;
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}

[hidden] {
  display: none !important;
}

.translate-180 {
  transform: rotate(180deg);
}

.pointer {
  cursor: pointer;
}

.no-select {
  user-select: none;
}

.error-text {
  @include error-text();
}

.error-text-inline-right {
  @include error-text(15px);
}

.oi {
  cursor: pointer;
}

.container {
  padding: var(--container-padding);
}

.container--low {
  padding: var(--container-padding-low);
}

.fullscreen-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--background);
  z-index: 200;
}

.container--round {
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  display: block;
  position: relative;
  padding: 24px;
  box-shadow: 8px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.container--no-padding {
  @extend .container--round;
  padding: 0;
}

.container--background {
  background: rgba(var(--palette-background-card));
}

.item-settings-nav-icon {
  margin: 15px;
  color: rgba(var(--palette-primary-600));
  font-size: 18px;
  float: right;
  cursor: pointer;
}

.half-left-div {
  width: calc(50% - 10px);
  float: left;
  margin-right: 10px;
}

.right-align {
  text-align: right;
}

.r-padding-10 {
  padding-right: 10px;
}

.clickable {
  cursor: pointer;
}

.col {
  &.col--big {
    flex: 2;
  }

  &.col--small {
    flex: 1;
  }
}

.alert-danger {
  color: var(--status-alert);
}
