a.button {
  text-align: center;
  line-height: 38px;
}
a.button,
button,
input[type="submit"] {
  position: relative;
  display: inline-block;
  box-sizing: border-box;

  height: 40px;
  white-space: nowrap;
  padding: 0 24px;
  border-radius: 5px;
  border: 1px solid currentColor;
  background-color: transparent;
  text-transform: uppercase;
  font-size: 16px;
  color: var(--primary-color);
  cursor: pointer;
  transition: color 0.2s, border-color 0.3s, background-color 0.4s,
    min-width 0.25s;
}
@media (max-width: 900px) {
  a.button,
  button,
  input[type="submit"] {
    min-width: 0;
  }
}
a.button:hover,
button:hover,
input[type="submit"]:hover {
  color: var(--secondary-color);
}
a.button:disabled,
button:disabled,
input[type="submit"]:disabled {
  color: var(--gray-4-color);
}
a.button:focus,
button:focus,
input[type="submit"]:focus {
  outline: none;
}

a.button.primary,
button.primary,
input[type="submit"].primary {
  color: var(--background);
  background-color: var(--primary-color);
  border: none;
}
a.button.primary:hover,
button.primary:hover,
input[type="submit"].primary:hover {
  background-color: var(--secondary-color);
}
a.button.primary:disabled,
button.primary:disabled,
input[type="submit"].primary:disabled {
  background-color: var(--gray-4-color);
}

a.button.transparent,
button.transparent,
input[type="submit"].transparent {
  border-color: transparent;
}
a.button.transparent:hover,
button.transparent:hover,
input[type="submit"].transparent:hover {
  border-color: currentColor;
}

a.button.icon,
button.icon,
input[type="submit"].icon {
  min-width: 40px;
  padding: 0;
  font-size: 26px;
}

a.button .label,
button .label,
input[type="submit"] .label {
  display: inline-block;
  max-width: 300px;
  overflow: hidden;
  vertical-align: middle;
  transition: max-width 0.25s;
}

a.button.icon .oi,
button.icon .oi,
input[type="submit"].icon .oi {
  font-size: 18px;
  text-transform: none;
}

.icon .oi.glyphs,
.icon .oi.glyphs2 {
  font-size: 25px;
  text-transform: none;
}

a.button .badge,
button .badge {
  position: absolute;
  top: 50%;
  left: 100%;
  margin: -12px 0 0 -12px;
  width: 24px;
  height: 24px;
  font-size: 14px;
  line-height: 24px;
  border-radius: 50%;
  color: var(--background);
  background-color: var(--primary-color);
}
a.button .badge.warn,
button .badge.warn {
  background-color: var(--status-warn-color);
}
a.button .badge.alert,
button .badge.alert {
  background-color: var(--status-alert-color);
}
a.button .badge.danger,
button .badge.danger {
  background-color: var(--status-danger-color);
}
