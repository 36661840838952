@use "@angular/material" as mat;

form {
  .form-group {
    &.horizontal {
      display: flex;

      .form-group {
        width: 100%;

        &.left {
          margin-right: 50px;
        }

        &.right {
          margin-left: 50px;
        }
      }
    }
  }

  .form-section:not(:first-of-type),
  .mat-expansion-panel {
    .mat-expansion-panel-body {
      .form-section {
        margin-top: 35px;
      }
    }
  }
}

h2.form-group-title {
  margin-top: 40px;
}

.mat-select-panel.select--options-multiline {
  .mat-option {
    height: unset;

    .mat-option-text {
      white-space: normal;
      line-height: 1.5em;
      padding: 10px 0;
    }
  }
}

.mat-focused {
  .search-icon {
    color: var(--primary-color);
  }
}
