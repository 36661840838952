@import "./mixins";

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes appear-from-left {
  from {
    transform: translateX(-50px);
    opacity: 0;
  }
  to {
    transform: none;
    opacity: 1;
  }
}

@keyframes appear-from-top {
  from {
    transform: translateY(-50px);
    opacity: 0;
  }
  to {
    transform: none;
    opacity: 1;
  }
}

@keyframes appear-list-item {
	@include increase-height(70px);
}

@keyframes fade {
	0%,
	100% {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
}
