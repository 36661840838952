$icon-background-color: #fff;
$border-radius: 7px;

.icon__groupped {
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  background-color: $icon-background-color;
  border-radius: $border-radius;
  display: flex;
  padding: 4px;
  width: 21px;
  cursor: pointer;
  user-select: none;

  i {
    font-size: 21px;
    line-height: 21px;
  }

  &:active {
    color: $icon-background-color;
    background-color: var(--primary-color);
  }

  &.icon__groupped--active {
    &,
    &:active {
      color: $icon-background-color;
      background-color: var(--primary-color);
    }
  }

  &.icon__groupped--bold {
    font-weight: bold;
  }

  &.icon__groupped--disabled {
    &,
    &:active {
      background-color: $icon-background-color;
      color: var(--gray-5-color);
      border-color: var(--gray-5-color);
    }
  }
}

.icons__groupped {
  .icon__groupped {
    border-radius: 0;

    &:first-child {
      border-radius: $border-radius $border-radius 0 0;
      border-bottom: 0;
    }

    &:last-child {
      border-radius: 0 0 $border-radius $border-radius;
    }
  }
}
